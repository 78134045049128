import React, {useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react";
import FiltersHeader from "./desktop/filtersHeader";
import {mzUseNavigate, useTranslationWord,} from "../../../../utils";
import {MobileList} from "./mobile";
import {DesktopList} from "./desktop";
import {RedemptionResponse} from "@jnext/ts-axios-mz";
import {OrderOrderStatusEnum} from "@jnext/ts-axios-mz/models/order";
import {CMSConfiguration, getNavPathFromType, JTextArea, NavigationTypes} from "@jnext/commons";
import {RedemptionService} from "../../../../service/RedemptionService";
import {useLoadingStore} from "store/loadingStore";
import {useCMSStore} from "../../../../store/cmsStore";
import {HistoryRewardType} from "../../../../type/historyReward";
import {Pagination} from "../../../Pagination";
import {CardListV2} from "./CardList";
import {InfoCircleOutlined} from "@ant-design/icons";
import MessageBox from "../../../Messages/MessageBox";
import {useMessages} from "../../../Messages/messageHooks";

interface HistoryRewardsContentProps {
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
}

const typeToShow = ["COMPANY_CLOSURE"]

const HistoryRewardsContent: React.FC<HistoryRewardsContentProps> = ({type}) => {
    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();
    const [initiatives, setInitiatives] = useState<RedemptionResponse[]>([]);
    const [statuses, setStatuses] = useState<OrderOrderStatusEnum[]>([]);
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
    const [redemptions, setRedemptions] = useState<RedemptionResponse[]>([]);
    const [options, setOptions] = useState<any>();

    //pagination
    const [totalPage, setTotalPage] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [lastPage, setLastPage] = useState<number>(0);

    // Page CMS
    const {pages} = useCMSStore();
    const config: CMSConfiguration | undefined = useMemo(() => pages?.profile, [pages?.profile]);

    // All Sections
    const sections = useMemo(() => {
        if (!config) {
            return [];
        }

        const sectionsTypes = {
            [HistoryRewardType.CONTEST]: 'HISTORY_REWARDS_CONTEST',
            [HistoryRewardType.CATALOG]: 'HISTORY_REWARDS_CATALOG',
            [HistoryRewardType.CATALOG_LOYALTY_COLLECTION]: 'HISTORY_REWARDS_CATALOG_LOYALTY_COLLECTION',
            [HistoryRewardType.CATALOG_DIGITAL_WALLET]: 'HISTORY_REWARDS_CATALOG_DIGITAL_WALLET',
        };

        const sectionType = sectionsTypes[type] || null;

        if (!sectionType) {
            return [];
        }

        const section = config?.section?.sections?.find(el => el.type == sectionType);
        setOptions(section?.options)

        // Return ections
        return section?.sections;
    }, [config]);

    // Specific section
    const cmsList = useMemo(() => sections?.find((el: any) => el.type == 'MAIN'), [sections]);
    const initiativeFormats = useMemo<string[] | undefined>(() => cmsList?.options?.initiativeFormats as any, [cmsList]);
    const cmsNoData = useMemo(() => sections?.find((el: any) => el.type == 'NO_DATA'), [sections]);

    // Loading status
    const [loaded, setLoaded] = useState<boolean>(false);
    const {setLoading} = useLoadingStore();

    //service retrieveRedemptionList
    async function getRetrieveRedemptionList(initiativeCode?: string, generalStatus?: any, dateValue?: string[]) {
        const size = options?.size || 20;
        let fromDate: string | undefined;
        let toDate: string | undefined;
        if (dateValue) {
            fromDate = dateValue[0];
            toDate = dateValue[1];
        }

        const redemptionList = await RedemptionService.retrieveRedemptionList(type, generalStatus, initiativeCode, fromDate, toDate, initiativeFormats, page, size);
        setTotalPage((redemptionList[0] as any)?.customData?.pageCount)
        setRedemptions(redemptionList);
        setFilter(redemptionList);
    }

    function setFilter(redemptionList: RedemptionResponse[]) {
        // set initiatives list
        setInitiatives([...new Map(redemptionList.map(item => [item['initiativeName'], item])).values()]);
        // set statuses
        setStatuses(
            redemptionList
                .filter(el => el.orders && el.orders.length > 0)
                // @ts-ignore
                .map(el => el.orders.filter(order => order.orderStatus).map(order => order.orderStatus))
                .filter(el => el.length > 0)
                .flatMap(el => el)
                // remove duplicates
                .filter((value: any, index: any, array: string | any[]) => {
                    return array.indexOf(value) === index;
                }) as OrderOrderStatusEnum[]
        );
    }

    // Load redemptions
    useEffect(() => {
        (async () => {

            try {
                // Show loader
                setLoading(true);
                // redemptions list
                await getRetrieveRedemptionList();
                setLoaded(true);

            } catch (err) {

            } finally {
                // Hide loader
                setLoading(false);
            }
        })();
    }, []);


    useEffect(() => {
        (async () => {
            if (page || page !== lastPage) {
                setLastPage(page)
                await getRetrieveRedemptionList();
            }
        })()
    }, [page]);


    /**
     * On filter needed
     * @param initiativeCode
     * @param generalStatus
     * @param dateValue
     */
    const handleChange = async (initiativeCode: string, generalStatus: any, dateValue: string[]) => {
        await getRetrieveRedemptionList(initiativeCode, [generalStatus], dateValue);
    };

    /**
     * Continue redemption flow
     * @param redemptionId
     */
    const continueRedemption = (redemptionId: string) => {
        navigate(getNavPathFromType(NavigationTypes.REDEEM, {redemptionId}));
    }

    const LegendRedemption = () => {
        return (<div className={'legendRedemption'}>
            <div className={'infoPoint'}><InfoCircleOutlined/></div>
            <div className={'text'}>{translateWord('legendOrderStatus')}</div>
        </div>)
    }

    const {messages} = useMessages();

    /**
     * Reward list
     */
    return <>
        {
            cmsList &&
            <div className="historyRewards">
                {/*<div className={'filterBtnContainer'}>*/}
                {/*    <img src={generatePathUrl('filter-icon-blu.svg')} />*/}
                {/*    <FilterButton onClick={() => setMobileFiltersOpen(true)} />*/}
                {/*</div>*/}

                <label className="title">{cmsList?.title}</label>
                {
                    messages
                        .filter((message) => typeToShow.includes(message?.messageType ?? ''))
                        .map((message) => {
                            return (
                                <MessageBox
                                    msgTitle={message?.msgTitle ?? ''}
                                    msgSubtitle={message?.msgSubtitle ?? ''}
                                    msgBody={message?.msgBody ?? ''}
                                    msgTitleColor={message?.msgTitleColor ?? ''}
                                    msgSubtitleColor={message?.msgSubtitleColor ?? ''}
                                    msgBodyColor={message?.msgBodyColor ?? ''}
                                    fromDate={message?.fromDate ?? ''}
                                    toDate={message?.toDate ?? ''}
                                    msgLogoUrl={message?.msgLogoUrl ?? ''}
                                    msgDisclaimerColor={message?.msgDisclaimerColor ?? ''}
                                />
                            )
                        })}

                <p className="subTitle">{cmsList?.subTitle}</p>

                <div className={`popup-filters ${mobileFiltersOpen ? 'open' : 'false'}`}>
                    <FiltersHeader
                        initiatives={initiatives ?? []}
                        handleChange={handleChange}
                        closeFilters={() => setMobileFiltersOpen(false)}
                        cms={cmsList}
                        showInitiative={type !== 'CATALOG'}
                    />
                </div>
                {
                    loaded && <>
                        {!options?.showHistoryCardV2 ? <>
                                <div className={'mobileDetails'}>
                                    <MobileList redemptions={redemptions}
                                                onRedeem={(redemptionId) => continueRedemption(redemptionId)} cms={cmsList}
                                                type={type}/>
                                </div>
                                <div className={'desktopDetails'}>
                                    <DesktopList redemptions={redemptions}
                                                 onRedeem={(redemptionId) => continueRedemption(redemptionId)} cms={cmsList}
                                                 type={type}/>
                                </div>
                            </>
                            : <>
                                <LegendRedemption/>
                                <div className={'cardListContainer'}>
                                    <CardListV2 redemptions={redemptions}
                                                onRedeem={(redemptionId) => continueRedemption(redemptionId)}
                                                cms={cmsList}
                                                options={options}
                                                type={type}/>
                                </div>
                            </>}


                        {options?.pagination && totalPage > 1 &&
                            <div className='pagination'>
                                <Pagination
                                    page={page + 1}
                                    totalPage={totalPage}
                                    updatePagination={(pageNr) => setPage(pageNr - 1)}
                                />
                            </div>}

                        {
                            redemptions.length == 0 && <>
                                {cmsNoData?.title}
                            </>
                        }
                    </>
                }
            </div>
        }
    </>
};

export default observer(HistoryRewardsContent);
