import {getNavPathFromType, NavigationTypes} from "@jnext/commons";
import {LoginV4Api} from "@jnext/ts-axios-coreuser";
import axios from "axios";
import {LoginType} from "../enums/LoginType";
import {ProfileFields} from "../type/interfaces";
import {DEFAULT_LANGUAGE} from "../type/languages";
import {browserRedirect, decodeBody, goToNoAuth} from "../utils";
import {HttpService} from "./HttpService";

export class AuthService {
    static maxRefreshMS = 604800000; // limite max di 7 giorni per il refreshToken
    static accessToken: string;

    static loginInfo: any = {
        project: localStorage?.getItem('project') ? localStorage?.getItem('project') : '0',
        solution: localStorage?.getItem('solution') ? localStorage?.getItem('solution') : 'mz',
        tenant: localStorage?.getItem('tenant') ? localStorage?.getItem('tenant') : '',
        defaultTimeZone: localStorage?.getItem('defaultTimeZone') ? localStorage?.getItem('defaultTimeZone') : 'Europe/Rome',
        user: '00adbba7-a7f7-4392-b7b1-bd07f60f505d',
    };

    static language: string = localStorage?.getItem('ln') || DEFAULT_LANGUAGE;

    static setLoginInfo(loginInfo: any) {
        this.loginInfo = loginInfo;
    }

    static get token() {
        return localStorage.getItem('token');
    }

    static setToken = (value?: string) => {
        if (typeof value === "string") {
            localStorage.setItem('token', value);
        }
    }

    static get refreshTokenCookie() {
        return localStorage.getItem('refresh_token') || localStorage.getItem('tmp_refresh_token') || '';
    }

    static setRefreshToken = (value?: string) => {
        if (typeof value === "string") {
            localStorage.setItem('refresh_token', value);
        }
    }

    static setTempToken(token?: string) {
        if (typeof token === "string") {
            this.accessToken = token
        }
    }

    static get loginType() {
        return localStorage.getItem('login_type');
    }

    static setLoginType = (value: LoginType) => {
        localStorage.setItem('login_type', value);
    }

    static setLanguage(language: string) {
        this.language = language;
        localStorage.setItem("ln", language);
    }

    static get authorized() {

        if (!this.token && this.token !== "" && this.token !== "undefined") {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            return false;
        }

        const parsedToken = JSON.parse(atob(this.token.split('.')[1]));

        if (!parsedToken || !parsedToken.exp) {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            return false;
        }

        const now = new Date();
        const expDateDt = new Date(parsedToken.exp * 1000);

        if (now > expDateDt) {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            return false;
        }

        return true;

    }

    /**
     * Parse tokens from url and return data as object
     */
    static parseUrlTokens(): {
        token: string,
        refreshToken: string,
        project: string,
        tenant: string,
        solution: string,
        format: string,
        language: string,
        accessCheck: string,
        redirect: string,
    } {
        const urlParams = new URLSearchParams(window.location.search);
        const extraPersistParams: Record<string, string> = {};
        const extraForwardParams: Record<string, string> = {};

        urlParams.forEach((value, key) => {
            if (key.startsWith('extra.persist.')) {
                extraPersistParams[key] = value;
            }
            if (key.startsWith('extra.forward.')) {
                extraForwardParams[key] = value;
            }
        });

        return {
            token: urlParams.get('token') as string,
            refreshToken: urlParams.get('refreshToken') as string,
            project: urlParams.get('project') as string,
            tenant: urlParams.get('tenant') as string,
            solution: urlParams.get('solution') as string,
            format: urlParams.get('format') as string,
            language: urlParams.get('language') as string,
            accessCheck: urlParams.get('accessCheck') as string,
            redirect: urlParams.get('redirect') as string,
        }
    }

    static saveUrlExtraParametersToLocalStorage(ssoPath: any) {
        const urlParams = new URLSearchParams(ssoPath);

        const extraPersistParams: Record<string, string> = {};
        const extraForwardParams: Record<string, string> = {};

        urlParams.forEach((value, key) => {
            if (key.startsWith('extra.persist.')) {
                extraPersistParams[key] = value;
            }
            if (key.startsWith('extra.forward.')) {
                extraForwardParams[key] = value;
            }
        });

        if (Object.keys(extraPersistParams).length > 0) {
            Object.entries(extraPersistParams).forEach(([key, value]) => {
                localStorage.setItem(key, value);
            });
        }

        if (Object.keys(extraForwardParams).length > 0) {
            Object.entries(extraForwardParams).forEach(([key, value]) => {
                localStorage.setItem(key, value);
            });
        }

    }

    static getUrlExtraParametersFromLocalStorage() {

        const urlParams = localStorage;
        //const urlParams = JSON.parse('{"' + decodeURI(queryParameters ?? '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')

        const extraForwardParams: Record<string, string> = {};

        Object.entries(urlParams).forEach(([key, value]) => {

            if (key.startsWith('extra.forward.')) {
                if (typeof value === "string") {
                    extraForwardParams[key] = value;
                }
            }
        });

        return new URLSearchParams(extraForwardParams).toString();

    }

    static updateLoginInfo() {
        if (this.token) {
            let exp = decodeBody(this.token as string);
            if (exp) {
                this.timerRefreshToken(exp);
            }
        }
    }

    static timerRefreshToken(timer?: number) {

        const newTimer = (timer || 0) * 1000;
        const now = new Date().getTime();
        if (timer && newTimer > now) {

            let diffTime = Math.min(((newTimer - now) - 60000), this.maxRefreshMS);

            setTimeout(() => {
                return this.refreshToken();
            }, diffTime);

        } else {
            return this.logout()
        }
    }


    static async refreshToken() {
        // @ts-ignore
        const loginApi: LoginV4Api = new LoginV4Api(undefined, HttpService.servicePath('core-user'), axios as any);
        try {
            const responseRefresh = await loginApi.refreshToken({
                refresh_token: this.refreshTokenCookie,
                clientId: HttpService.env?.keycloakClientID
            });
            const {access_token, refresh_token} = responseRefresh?.data;
            this.setToken(access_token as string);
            const evt = new CustomEvent("refresh_token", {detail: {token: access_token}});
            document.dispatchEvent(evt);
            this.setRefreshToken(refresh_token as string);
            return this.updateLoginInfo();
        } catch (e: any) {
            console.log("Triggered refresh api exception", e)
            goToNoAuth();
        }
    }

    static async logout() {
        // try to do logout
        try {
            // @ts-ignore
            const loginApi: LoginV4Api = new LoginV4Api(undefined, HttpService.servicePath('core-user'), axios);
            await loginApi.logout({
                refresh_token: this.refreshTokenCookie || '',
                clientId: HttpService.env?.keycloakClientID
            }, {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    tenant: this.loginInfo?.tenant
                }
            });

        } catch (e) {
            // Can not do logout on Server
        }
        // Logout clean procedure
        this.logoutClean();
        localStorage.clear();


        // redirect to home
        browserRedirect(getNavPathFromType(NavigationTypes.PUBLIC_HOME));
    }

    /**
     * Clean local data after logout
     */
    static logoutClean() {

        // Remove tokens from client
        this.unsetUserTokens();

        // Clear profile fields
        this.clearProfileFields();
    }

    /**
     * Remove token and refresh token
     */
    static unsetUserTokens = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('login_type');
    }

    /**
     * Clear profile fields from session
     */
    static clearProfileFields() {
        localStorage.removeItem('name');
        localStorage.removeItem('surname');
        localStorage.removeItem('clusterName');
        localStorage.removeItem('clusterId');
    }

    /**
     * Save ProfileFields in session
     * @param profileFields
     */
    static saveProfileFields(profileFields: ProfileFields) {
        if (profileFields?.name) {
            localStorage.setItem('name', profileFields.name);
        }
        if (profileFields?.surname) {
            localStorage.setItem('surname', profileFields.surname);
        }
        if (profileFields?.clusterName) {
            localStorage.setItem('clusterName', profileFields.clusterName);
        }
        if (profileFields?.clusterId) {
            localStorage.setItem('clusterId', profileFields.clusterId);
        }
    }

    /**
     * Get ProfileFields in session
     * @param profileFields
     */
    static getProfileFields() {
        return {
            name: localStorage.getItem('name'),
            surname: localStorage.getItem('surname'),
            clusterName: localStorage.getItem('clusterName'),
            clusterId: localStorage.getItem('clusterId'),
        }
    }

    /**
     * Copy token and refreshToken from url to cookies
     */
    static saveUrlTokenToCookies() {
        const {token, refreshToken} = AuthService.parseUrlTokens();

        if (token) {
            this.setToken(token);
        }
        if (refreshToken) {
            this.setRefreshToken(refreshToken);
        }

        return
    }

    /**
     * Set needed token from URL
     */
    static verifyUrlTokens(setLanguage: (language: string) => void) {
        const {solution, format, tenant, project, language} = AuthService.parseUrlTokens();

        if (solution) {
            this.loginInfo.solution = solution;
            localStorage.setItem('solution', solution);
        }

        if (tenant) {
            this.loginInfo.tenant = tenant;
            localStorage.setItem('tenant', tenant);
        }
        if (project) {
            this.loginInfo.project = project;
            localStorage.setItem('project', project);
        }

        if (format) {
            this.loginInfo.format = format;
        }
        if (language) {
            setLanguage(language);
        }
    }

    static async generateSsoToken(tenant: string, project: string, isSamlSso: boolean, email?: string, SAMLResponse?: string, clientId?: string): Promise<{
        access_token: string,
        refresh_token: string
    }> {

        const body = {username: email, clientId: HttpService.env?.keycloakClientID}
        const params = new URLSearchParams();
        let headers = {
            tenant,
            project,
            'jak-tenant': tenant,
            'jak-project': project,
            'Content-Type': 'application/json;charset=UTF-8'
        }

        let contentType = '';

        if (isSamlSso) {
            params.append('SAMLResponse', SAMLResponse ?? '');

            if ((clientId?.length ?? 0) > 0) {
                params.append('clientId', clientId ?? '');
            }

            contentType = 'application/x-www-form-urlencoded';

            headers = {
                ...headers,
                "Content-Type": 'application/x-www-form-urlencoded'
            }
        }

        const url = isSamlSso
            ? '/v4/sso/saml'
            : '/sso/login'

        const res: any = await HttpService.publicHttpApi('POST', 'core-user', url,
            isSamlSso ? params : {...body}, headers, contentType);

        if (!isSamlSso) {
            return res?.data;
        } else {
            return {
                access_token: res.request.responseURL.split("token=")[1],
                refresh_token: ''
            }
        }
    }

}