import axios from "axios";
import {OrderStatusEnum, RedemptionResponse, RedemptionResponseStatusEnum, RedemptionsApi} from "@jnext/ts-axios-mz";
import {Totals} from "@jnext/ts-axios-mz/models/totals";
import {ProductType} from "../enums/ProductType";
import {HttpService} from "./HttpService";
import {RedemptionApi, RedemptionStatusEnum} from "@jnext/ts-axios-reward";
import {AuthService} from "./AuthService";
import {InitiativeFormatEnum} from "@jnext/ts-axios-mz";
import {HistoryRewardType} from "../type/historyReward";

export class RedemptionService {
    static module = "solution-mz";

    static async updateRedemptionAddress(redemptionId: string, redemptionInfoObject: any) {
        const loginInfo = AuthService.loginInfo;
        // @ts-ignore
        const redemptionApi = new RedemptionApi(undefined, HttpService.servicePath('core-reward'), axios);
        const res = await redemptionApi.updateRedemptionAddress(redemptionId, loginInfo.languageCode, redemptionInfoObject)
        return res.data
    }

    static async retrieveRedemptionList(
        rewardType: HistoryRewardType,
        generalStatus?: any,
        initiativeCode?: string,
        fromDate?: string,
        toDate?: string,
        overrideTypes?: string[],
        page?: number,
        size?: number,
    ): Promise<RedemptionResponse[]> {
        const types = {
            [HistoryRewardType.CATALOG]: [InitiativeFormatEnum.DigitalCollection.toUpperCase()],
            [HistoryRewardType.CATALOG_LOYALTY_COLLECTION]: [InitiativeFormatEnum.LoyaltyCollection.toUpperCase()],
            [HistoryRewardType.CATALOG_DIGITAL_WALLET]: [InitiativeFormatEnum.DigitalWallet.toUpperCase()],
            [HistoryRewardType.CONTEST]: [InitiativeFormatEnum.Contest.toUpperCase(), InitiativeFormatEnum.Event.toUpperCase()],
            [HistoryRewardType.ALL]: [/*InitiativeFormatEnum.LoyaltyCollection.toUpperCase(), InitiativeFormatEnum.DigitalCollection.toUpperCase(), InitiativeFormatEnum.Contest.toUpperCase(), InitiativeFormatEnum.Event.toUpperCase()*/],
        }

        let orderStatuses: OrderStatusEnum[] = [];
        let redemptionStatus: RedemptionStatusEnum[] = [];

        if (generalStatus) {

            const orderPrefix = "ORDER_";
            const redemptionPrefix = "REDEMPTION_";

            Object.keys(generalStatus).forEach(statusKey => {
                const statusValue = generalStatus[statusKey];
                if (statusValue.includes(orderPrefix)) {
                    const orderStatus = statusValue.replace(orderPrefix, "") as OrderStatusEnum;
                    orderStatuses.push(orderStatus);
                    // Se c'è uno stato d'ordine, redemption è sempre COMPLETED
                    redemptionStatus = [RedemptionStatusEnum.Completed];
                } else if (statusValue.includes(redemptionPrefix)) {
                    const redemption = statusValue.replace(redemptionPrefix, "") as RedemptionStatusEnum;
                    // Se il valore è "WAITING", aggiungi sia WAITING che INSERTED
                    if (redemption === RedemptionStatusEnum.Waiting) {
                        redemptionStatus = [RedemptionStatusEnum.Waiting, RedemptionStatusEnum.Inserted];
                    } else {
                        redemptionStatus = [redemption];
                    }
                }
            });
        }

        // Need initiative filter?
        const initiatives = overrideTypes || types[rewardType];

        // @ts-ignore
        const redApi = new RedemptionsApi(undefined, HttpService.servicePath(this.module), axios);
        const res = await redApi.getRedemptions(initiativeCode, fromDate, toDate, initiatives as any, redemptionStatus, orderStatuses, page, size);
        return res.data;
    }

    static async retrieveRedemptionDetails(redemptionId: string) {
        // @ts-ignore
        const redApi = new RedemptionsApi(undefined, HttpService.servicePath(this.module), axios);
        const res = await redApi.getRedemption(redemptionId);
        return res.data;
    }

    /**
     * Redemption already completed
     * @param status
     */
    static completed(status: RedemptionResponseStatusEnum | string | undefined) {
        // return redemption.issues?.length == 0 && redemption.orders && redemption.orders.length > 0;
        return status == 'COMPLETED';
    }

    /**
     * Redemption already deleted
     * @param status
     */
    static deleted(status: RedemptionResponseStatusEnum | undefined) {
        // return redemption.issues?.length == 0 && redemption.orders && redemption.orders.length > 0;
        return status == 'DELETED';
    }

    /**
     * Need to finalize redemption
     * @param status
     */
    static needToFinalize(status: RedemptionResponseStatusEnum | string | undefined) {

        return status == RedemptionResponseStatusEnum.WaitingForUser;

        // return redemption.issues &&
        //         redemption.issues
        //         .map((issue: any) => issue.issueType)
        //         .find((issueType: IssueIssueTypeEnum) => [IssueIssueTypeEnum.AddressPending, IssueIssueTypeEnum.ApprovalPending].indexOf(issueType) != -1)
        //     != undefined;
    }

    /**
     * Check if show totals
     * @param totals
     */
    static showTotals(totals?: Totals) {
        const showTotals = totals?.requiredPoints?.find(point => !!point.amount && point.amount != 0);
        if (!showTotals) {
            return false;
        }

        return true;
    }

    /**
     * Check if redemption has a physical product
     * @param redemption
     */
    static hasPhysicalProducts(redemption: RedemptionResponse): boolean {
        return redemption.orderItems?.find(item => item.itemType == ProductType.PHYSICAL) != undefined;
    }
}