import {getNavPathFromType, JButton, JCheckbox, JPage, JTextArea, JTextField} from "@jnext/commons";
import {Col, Form, Row} from "antd";
import React, {useEffect, useState} from "react";
import {AuthService} from "../../service/AuthService";
import './style.scss';
import {Navigate} from "react-router-dom";
import {browserRedirect} from "../../utils";

const GenerateSSO: React.FC = () => {

    useEffect(() => {
        // Remove old user data
        localStorage.clear();
        AuthService.logoutClean();
    }, []);
    // Close on production
    if (
        !window.location.hostname.includes('-dev-') &&
        !window.location.hostname.includes('-stage-') &&
        !window.location.hostname.includes('localhost')
    ) {

        return <Navigate to='/404'/>
    }

    const [form] = Form.useForm();

    const [accessToken, setTempToken] = useState<string>('');
    const [refreshToken, setRefreshToken] = useState<string>('');
    const [project, setProject] = useState<string>('');
    const [tenant, setTenant] = useState<string>('');
    const [tokenReady, setTokenReady] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loginInfo, ] = useState(AuthService.loginInfo);
    const [samlSsoCheck, setSamlSsoCheck] = useState<boolean>(false);
    const [tokenErrorMessage, setTokenErrorMessage] = useState<string | undefined>(undefined);
    const [tokenErrorCode, setTokenErrorCode] = useState<string | undefined>(undefined);

    const onFinish = ({project, tenant, email, SAMLResponse, clientId}: any) => {

        setTokenErrorMessage(undefined);
        setTokenErrorCode(undefined);

        // if already loading exit
        if (loading) {
            return;
        }

        // set as loading...
        setLoading(true);

        AuthService.generateSsoToken(tenant, project, samlSsoCheck, email, SAMLResponse, clientId)
            .then(({access_token, refresh_token}) => {
                // set token and access token
                setTempToken(access_token);
                setRefreshToken(refresh_token);
                setProject(project);
                setTenant(tenant);

                // set token as ready
                setTokenReady(true);

                // Remove all user data
                AuthService.logoutClean();

            })
            .catch(e => {
                console.log(e)
            })
            .finally(() => {
                console.log("finally");
                // remove loading
                setLoading(false);
            })


    }

    const doLoginSSO = (token: string) => {
        browserRedirect(getNavPathFromType(token));
    }
    return (
        <JPage enabled>
            <Row className={'generate-sso-container'} justify={'center'}>
                <Col span={12}>
                    <h1>Genera link SSO</h1>
                    <Form form={form} onFinish={onFinish} layout="vertical" onChange={() => {
                        setTokenReady(false);
                    }}>
                        <Form.Item
                            name='sso-saml'
                            rules={[
                                {
                                    required: false
                                },
                            ]}
                        >
                            <JCheckbox
                                nameInput='selectSamlSSO'
                                label={'SSO Saml'}
                                checked={samlSsoCheck}
                                disabled={false}
                                onChange={() => setSamlSsoCheck(prevCheck => !prevCheck)}
                            />
                        </Form.Item>

                        <Form.Item
                            name='tenant'
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserire valore'
                                },
                            ]}
                            initialValue={loginInfo?.tenant}>
                            <JTextField
                                type="text"
                                name='tenant'
                                placeholder={'Tenant'}
                            />
                        </Form.Item>
                        <Form.Item
                            name='project'
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserire valore'
                                },
                            ]}
                            initialValue={loginInfo?.project}>
                            <JTextField
                                type="text"
                                name='project'
                                placeholder={'Project'}
                            />
                        </Form.Item>

                        {!samlSsoCheck

                            ?
                            <Form.Item
                                name='email'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Inserire valore'
                                    }
                                ]}>
                                <JTextField
                                    type="text"
                                    name='email'
                                    placeholder={'Email'}
                                />
                            </Form.Item>
                            :

                            <>

                                <Form.Item
                                    name='SAMLResponse'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Inserire valore'
                                        }
                                    ]}>
                                    <JTextArea
                                        name="SAMLResponse"
                                        textAreaProps={{size: 'large'}}
                                        placeholder={'SAMLResponse'}
                                        maxLength={1000000}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='clientId'
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Inserire valore'
                                        }
                                    ]}>
                                    <JTextField
                                        type="text"
                                        name='clientId'
                                        placeholder={'clientId'}
                                    />
                                </Form.Item>

                            </>

                        }

                        <Form.Item>
                            <JButton
                                htmlType="submit"
                                shape="round"
                                type="primary"
                                className="btnPrimaryDark btn"
                            >Genera</JButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            {
                tokenReady &&
                <Row justify={'center'}>
                    <Col>
                        <h1 className={'sso-link'} onClick={() => doLoginSSO(`/sso?token=${accessToken}&refreshToken=${refreshToken}&project=${project}&tenant=${tenant}`)}>
                            Clicca qui per accedere con SSO
                        </h1>
                    </Col>
                </Row>
            }

            {
                tokenErrorMessage &&
                <Row justify={'center'}>
                    <Col>
                        <p><strong>message:</strong> {tokenErrorMessage} {tokenErrorCode &&
                            <span> <strong>code:</strong> {tokenErrorCode} </span>} </p>
                    </Col>
                </Row>
            }
            {
                loading &&
                <Row className={'generating-token'} justify={'center'}>
                    <Col>
                        Genero token...
                    </Col>
                </Row>
            }
        </JPage>
    );
}


export default GenerateSSO;