import {CMSSection} from "@jnext/commons";
import React from "react";
import {Item, Order, RedemptionResponse, RedemptionResponseStatusEnum, Product} from "@jnext/ts-axios-mz";
import {CardItemV2} from "../../../../commons/JCardV2/CardItemV2";
import {HistoryRewardType} from "../../../../type/historyReward";
import {OrderOrderStatusEnum} from "@jnext/ts-axios-mz/models/order";
import {ProductDetailBrandDto} from "@jnext/ts-axios-mz/models/product-detail-brand-dto";

interface CardListProps {
    redemptions: RedemptionResponse[];
    onRedeem: (redemptionId: string) => void;
    cms: CMSSection;
    options?: any;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
}

export interface RedemptionProps {
    logicId: string;
    approvalRequested: string;
    status: RedemptionResponseStatusEnum | string | undefined;
    friendlyId: string;
    initiativeName: string;
    initiativeFormat: string;
    dateTime: string;
    expirationDate?: string;
    product: Product | undefined;
}

export interface OrderProps {
    orderStatus: OrderOrderStatusEnum;
    orderDate: string;
    product: Item;
}

const getRedemptionProps = (redemption: RedemptionResponse): RedemptionProps => {
    return {
        logicId: redemption?.logicId,
        approvalRequested: redemption?.approvalRequested,
        status: redemption.status,
        friendlyId: redemption?.friendlyId,
        initiativeName: redemption?.initiativeName,
        initiativeFormat: redemption?.initiativeFormat,
        dateTime: redemption?.dateTime,
        product: redemption?.orderItems?.[0]?.product,
        expirationDate: redemption?.expirationDate
    };
}

const getOrderProps = (redemption: RedemptionResponse): OrderProps => {
    return redemption?.orders?.map((red: Order) => {
        return {
            orderStatus: red?.orderStatus,
            orderDate: red?.date,
            product: red?.products[0],
        };
    })[0] as OrderProps;
}

export const CardListV2: React.FC<CardListProps> = ({
                                                        redemptions,
                                                        onRedeem,
                                                        cms,
                                                        options,
                                                        type
                                                    }) => {
    return (
        <>
            {
                redemptions?.map((redemption, i) => {
                        //function to get the redemption props
                        let redemptionProps: RedemptionProps | undefined = getRedemptionProps(redemption);

                        //function to get the order props
                        let orderProps: OrderProps | undefined = getOrderProps(redemption);

                        let brand: ProductDetailBrandDto | undefined = orderProps?.product?.product?.brands?.[0] || redemptionProps?.product?.brands?.[0];

                        return (
                            <div>
                                <CardItemV2 key={i}
                                            redemptionProps={redemptionProps}
                                            orderProps={orderProps}
                                            cms={cms}
                                            type={type}
                                            options={options}
                                            brand={brand}
                                            onRedeem={() => onRedeem(redemption?.logicId)}/>
                            </div>

                        )
                    }
                )
            }

        </>
    );
}