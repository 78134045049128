import {Order, OrderOrderStatusEnum} from "@jnext/ts-axios-mz/models/order";
import {DeliveryDto} from "@jnext/ts-axios-mz/models/delivery-dto";
import {Col} from "antd";
import React from "react";
import {useTranslationWord} from "../../../../utils";
import {CMSSection} from "@jnext/commons";

interface TrackingProps {
    order: Order,
    key: number,
    setOrderStatus?:(orderStatus: OrderOrderStatusEnum) => void,
    headCms?: CMSSection;
}

export const Tracking = ({order, key, setOrderStatus, headCms}: TrackingProps) => {

    const translateWord = useTranslationWord();

    setOrderStatus && setOrderStatus(order?.orderStatus);

    const deliveries = order?.deliveries?.filter(
        (del: DeliveryDto) => (del?.status === 'SENT' || del?.status === 'DELIVERED') && del?.trackingLink && del?.trackingLink != '');

    if (!deliveries || deliveries?.length === 0) return <></>;

    return (
        <Col className={'tracking'} key={key}>
            <div className={'tracking-key'}>{headCms?.table?.trackingLinkLabel?.label}</div>
            <div
                className={'tracking-value'}>
                {deliveries?.map((delivery: DeliveryDto) => {
                    return (
                        <a href={delivery?.trackingLink}
                           target={'_blank'}
                           title={delivery?.trackingLink}
                           aria-label={`${translateWord('GO_TO')} ${delivery?.trackingLink}`}
                           className={'tracking-link'}>
                            {headCms?.table?.trackingLinkCtaLabel?.label} ${delivery?.trackingLink}
                        </a>
                    )
                })}
            </div>
        </Col>
    )
}